.welcome-box-container {
    background: url(/images/background.jpg) no-repeat;
    background-size: cover;
    padding: 24px;
}


.welcome-box {
    padding-top: 20px;
    display: inline-block;
    color: #fff;
}

.welcome-box__title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    color: white;
    margin-bottom: 20px;
    border-bottom: 3px solid #fff;
    padding-bottom: 5px;
}

.expandable {
    &.ant-collapse-borderless {
        background-color: transparent !important;
    }
    .ant-collapse-header {
        padding-left: 0 !important;
        display: inline-block;
    }
    .ant-collapse-content-box {
        padding: 0 !important;
    }
    .ant-collapse-item {
        border-bottom: none !important;
    }
    h2 {
        margin-bottom: 0;
    }
}
