
.ant-list-item {
    &.navbar__item {
        line-height: 48px;
        padding: 0;
        justify-content: normal;
        a {
            cursor: pointer;
        }
        &--clickable {
            cursor: pointer;
        }
        .navbar__icon {
            display: inline-block;
            margin-right: 10px;
            width: 48px;
            text-align: center;
        }
    }
}
