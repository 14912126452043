.navbar__logo {
    @media only screen and (max-width: 600px) {
        width: 200px;
    }
    @media only screen and (max-width: 360px) {
        width: 180px;
    }
}

.logo {
    float: left;
}

.ant-layout-header .ant-menu {
    border-bottom: 0;
}

.nav-drawer {
    .ant-drawer-header {
        height: 64px;
    }
    .ant-drawer-body {
        padding: 0;
    }
}
