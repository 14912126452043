.content {
    padding: 0;
}

.ant-layout-header {
    background-color: red !important;
    padding: 0 20px !important;
}

.genre-filters .ant-checkbox-group-item {
    min-width: 100px
}

.site-layout-content {
    margin-top: 0;
}

.movie {
    display: flex;

    &__poster {

    }
}

.movie-list {
    margin: 0 -24px;
    padding: 0;
    &__item {
        padding: 20px;
        background-image: linear-gradient(to right, #ccc 33%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 16px 3px;
        background-repeat: repeat-x;
        @media only screen and (max-width: 600px) {
            padding: 20px 0;
        }
    }
}

.movie__poster-container {
    flex: 0 1 auto;
    margin-right: 20px;
    @media only screen and (max-width: 600px) {
        margin-right: 10px;
    }
}

.movie__poster {
    width: 100%;
}

.movie__content {
    max-width: 600px;
    flex: 1 1.5 600px;
}

.movie__title {
    font-size: 24px;
    color: #ff4444;
}

.movie__year {
    color: #999;
    font-weight: normal;
}

.movie__info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    .movie__certificate {
        margin-right: 10px;
        margin-bottom: 1em;
        align-self: center;
        line-height: 30px;
    }
    .movie__genre {
        margin-right: 16px;
        align-self: center;
        margin-bottom: 1em;
    }
    .movie__runtime {
        border: 2px dashed #fff;
        padding: 2px 5px;
        background-color: #333;
        color: white;
        align-self: center;
        margin-bottom: 1em;
    }
}

.certificate {
    height: 32px;
    width: 32px;
    color: black;
}

.certificate--default {
    border: 1px solid black;
    padding: 0 5px;
    text-align: center;
    width: auto;
    min-width: 32px;
    border-radius: 16px;
}

.certificate--uk-U {
    background: url(/images/certificates/uk/U.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.certificate--uk-PG {
    background: url(/images/certificates/uk/PG.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.certificate--uk-12 {
    background: url(/images/certificates/uk/12.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.certificate--uk-12A {
    background: url(/images/certificates/uk/12A.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.certificate--uk-15 {
    background: url(/images/certificates/uk/15.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.certificate--uk-18 {
    background: url(/images/certificates/uk/18.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.certificate--us-G {
    background: url(/images/certificates/us/G.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.certificate--us-PG {
    background: url(/images/certificates/us/PG.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.certificate--us-PG-13 {
    background: url(/images/certificates/us/PG-13.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.certificate--us-R {
    background: url(/images/certificates/us/R.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.certificate--us-NC-17 {
    background: url(/images/certificates/us/NC-17.svg) no-repeat;
    background-size: 32px 32px;
    color: transparent;
}

.movie__scores {
    display: flex;
}

.movie__imdb-score {
    display: block;
    background: url(/images/imdb.svg) no-repeat;
    background-size: 32px 32px;
    padding-left: 38px;
    height: 32px;
    line-height: 32px;
    margin-bottom: 1em;
    margin-right: 20px;
}

.movie__rt-critics-score {
    display: block;
    background: url(/images/rt_fresh.svg) no-repeat;
    background-size: 32px 32px;
    padding-left: 36px;
    height: 32px;
    line-height: 32px;
    margin-bottom: 1em;
    margin-right: 20px;
    &.movie__rt-critics-score--fresh {
        background: url(/images/rt_certified_fresh.svg) no-repeat;
        background-size: 32px 32px;
    }
    &.movie__rt-critics-score--rotten {
        background: url(/images/rt_rotten.svg) no-repeat;
        background-size: 32px 32px;
    }
}

.movie__rt-audience-score {
    display: block;
    background: url(/images/rt_upright.svg) no-repeat;
    background-size: 32px 32px;
    padding-left: 36px;
    height: 32px;
    line-height: 32px;
    margin-bottom: 1em;
    &.movie__rt-audience-score--upright {
        background: url(/images/rt_upright.svg) no-repeat;
        background-size: 32px 32px;
    }
    &.movie__rt-audience-score--spilled {
        background: url(/images/rt_spilled.svg) no-repeat;
        background-size: 32px 32px;
    }
}

.movie__directors {
    list-style: none;
    padding: 0;
}

.movie__directors-item {
    display: inline-block;
    &:not(:last-child) {
        margin-right: 5px;
        &::after  {
          content: ', ';
        }
    }
}

.movie__cast {
    list-style: none;
    padding: 0;
}

.movie__cast-item {
    display: inline-block;
    &:not(:last-child) {
        margin-right: 5px;
        &::after  {
          content: ', ';
        }
    }
}

.data-list {
    margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
    .ant-slider-mark-text:nth-child(even) {
        display: none;
    }
}

.dark {
    .movie__title {
        color: #aaa;
        a {
            color: #ff2222;
        }
    }
    .movie-list {
        color: #fff;
        background-color: #111;
        &__item {
            background-image: linear-gradient(to right, white 33%, rgba(255,255,255,0) 0%);
        }
    }
}

.show-more__container {
    margin-top: 24px;
    .show-more__button {
        width: 100%;
        height: auto;
        padding: 10px;
    }
}


@media only screen and (max-width: 600px) {
    .show-more__container {
        margin: 24px -24px 0;
    }
}

.loading--centered.ant-spin-spinning {
    margin: 20px auto 0;
    display: block;
}

.friends-list {
    width: 100%;

    label {
        display: block;
        padding: 10px 0;
        img {
            margin-right: 20px;
        }
        padding-bottom: 10px;
    }
    .ant-checkbox-wrapper::after {
        display: none;
    }
    &.ant-checkbox-group {
        direction: rtl;
        .ant-checkbox {
            top: 1.2em;
        }
        .ant-checkbox-group-item {
            margin-right: 0;
        }
    }
    .ant-checkbox + span {
        line-height: 50px;
        display: block;
        margin-bottom: 0;
        padding-bottom: 0;
        margin-top: -18px;
        padding: 0;
    }
}

.friend-filter-name {
    display: block;
    font-style: italic;
}

.ant-checkbox-group.friend-checkboxes {
    display: block;
    margin-bottom: 10px;
}

.ant-btn.movie-button {
    &__seen {
        background-color: #f0f0f0;
        &:hover {
            background-color: #f0f0f0;
        }
    }
    &__watchlisted {
        background-color: #f0f0f0;
        &:hover {
            background-color: #f0f0f0;
        }
    }
}
