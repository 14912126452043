.movie__poster-container {
    overflow: hidden;
}

.game-wrapper {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
}

.game-data {
    margin-top: 5px;
    margin-bottom: 0;
    &__key {
        float: left;
        margin-right: 5px;
        font-weight: bold;
        &:after {
            content: ":"
        }
    }
    &__value {
        margin-bottom: 0;
    }

    &__title {
        padding: 6px;
        font-size: 18px;
        font-weight: bold;
        &--solved {
            background-color: #33ee44;
        }
        &--failed {
            background-color: #fa0c2c;
        }
    }
    &__poster-container {
        overflow: hidden;
        max-width: 220px;
        margin: 0 auto 0;
    }
    &__poster {
        width: 100%;
        &--blur-0 {
            filter: blur(16px);
        }
        &--blur-1 {
            filter: blur(14px);
        }
        &--blur-2 {
            filter: blur(12px);
        }
        &--blur-3 {
            filter: blur(10px);
        }
        &--blur-4 {
            filter: blur(7px);
        }
        &--blur-5 {
            filter: blur(4px);
        }
    }
}

.guess-list {
    padding-left: 0;
    margin-top: 5px;
    margin-bottom: 0;
}

.guess-list-item {
    display: flex;
    margin-bottom: 3px;
    &__correct {
        .guess-text {
            border-color: #33ee44;
            background-color: #33ee44;
            color: #000;
        }
    }
    &__correct-words {
        .guess-text {
            border-color: orange;
        }
    }
    &__pass {
        .guess-text {
            background-color: #ddd;
            &:after {
                content: "PASS"
            }
        }
    }
}

.guess-word__correct {
    color: orange;
}

.guess-controls {
    display: flex;
    margin: 8px 0;
}

.pass-button.ant-btn {
    &:hover, &:focus, &:active {
        border-color: #aaa;
        color: #333;
    }
    text-transform: uppercase;
    padding: 6px;
    font-size: 12px;
}

.guess-button.ant-btn {
    &:hover, &:focus, &:active {
        background-color: #010f38;
        border: none;
        color: white;
    }
    background-color: #111f48;
    border: none;
    color: white;
    text-transform: uppercase;
    padding: 6px;
    font-size: 12px;
}

.ant-input.guess-input {
    border: 1px solid #aaa;
}

.guess-number {
    padding: 5px;
    color: #999;
    &:after {
        content: ".";
    }
}

.guess-text {
    padding: 5px;
    border: 1px solid #ddd;
    width: 100%;
    color: #999;
}

table.charts-css.stats-chart {
    td {
        padding-right: 5px;
        min-width: 18px;
    }
}

.stats-chart {
    &.charts-css.bar.show-labels {
        --labels-size: 50px !important;
    }
}

.stats-button.ant-btn {
    position: absolute;
    right: 24px;
}
